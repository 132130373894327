// 環境変数
export const URL_NAME: string = process.env.GATSBY_URL_NAME || "";
export const BASE_API_URL: string = process.env.GATSBY_BASE_API_URL || "";
export const FIREBASE_API_KEY: string =
  process.env.RGATSBY_FIREBASE_API_KEY || "";
export const FIREBASE_AUTH_DOMAIN: string =
  process.env.GATSBY_FIREBASE_AUTH_DOMAIN || "";
export const FIREBASE_DATABASE_URL: string =
  process.env.GATSBY_FIREBASE_DATABASE_URL || "";
export const FIREBASE_PROJECT_ID: string =
  process.env.GATSBY_FIREBASE_PROJECT_ID || "";
export const FIREBASE_STORAGE_BUCKET: string =
  process.env.GATSBY_FIREBASE_STORAGE_BUCKET || "";
export const FIREBASE_MESSAGING_SENDER_ID: string =
  process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID || "";
export const FIREBASE_APP_ID: string = process.env.GATSBY_FIREBASE_APP_ID || "";
export const FIREBASE_MEASUREMENT_ID: string =
  process.env.GATSBY_FIREBASE_MEASUREMENT_ID || "";

export const CONTENTFUL_SPACE_ID: string =
  process.env.GATSBY_CONTENTFUL_SPACE_ID || "";
export const CONTENTFUL_DELIVERLY_ACCESS_TOKEN: string =
  process.env.GATSBY_CONTENTFUL_DELIVERLY_ACCESS_TOKEN || "";

export const CONTACT_FORM_URL: string =
  process.env.GATSBY_CONTACT_FORM_URL || "";

export const ALGOLIA_APP_ID: string = process.env.GATSBY_ALGOLIA_APP_ID || "";
export const ALGOLIA_SEARCH_KEY: string =
  process.env.GATSBY_ALGOLIA_SEARCH_KEY || "";
export const ALGOLIA_INDEX_NAME: string =
  process.env.GATSBY_ALGOLIA_INDEX_NAME || "";

export const GITHUB_CLIENT_ID: string = "00f5b06947704ce81da3";

export const SNS_TWITTER_URL: string = "";
export const SNS_FACEBOOK_URL: string = "";
export const SNS_INSTAGRAM_URL: string = "";

// for cloudinary
export const CLOUDINARY_THUMBNAIL: string = "pthotos-thumbnail";
export const CLOUDINARY_SP: string = "t_photos_full_sp";
export const CLOUDINARY_PC: string = "t_pthotos-full";
