import * as React from "react";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { URL_NAME } from "../../config/constans";

interface MetaProps {}

const Meta: FC<MetaProps> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Life is what you make it @2020 by kame's"
        />

        <meta property="og:url" content={`${URL_NAME}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="kame's blog" />
        <meta
          property="og:description"
          content="Life is what you make it @2020 by kame's"
        />
        <meta
          property="og:image"
          content={`${URL_NAME}/img/cropped-turatle-192x192.png`}
        />
        <meta
          property="og:image:secure_url"
          content={`${URL_NAME}/img/cropped-turatle-192x192.png`}
        />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary" />

        <title>kame's blog</title>

        <link rel="icon" href="/img/cropped-turatle-32x32.png" sizes="32x32" />
        <link
          rel="icon"
          href="/img/cropped-turatle-192x192.png"
          sizes="192x192"
        />
        <link
          rel="apple-touch-icon-precomposed"
          href="/img/cropped-turatle-180x180.png"
        />
        <meta
          name="msapplication-TileImage"
          content={`${URL_NAME}/img/cropped-turatle-270x270.png`}
        />

        <script
          src="https://kit.fontawesome.com/557ec7b1cd.js"
          crossOrigin="anonymous"
        />
      </Helmet>
    </>
  );
};

export { Meta };
