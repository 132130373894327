import * as React from "react";
import { NavBar } from "../Navbar";
import styled, { createGlobalStyle } from "styled-components";
import { theme } from "../theme";
import { useEffect, useRef, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { Meta } from "../Meta";
import Footer from "../Footer";
import ErrorBoundary from "../ErrorBoundary";
import { useAuthHooks } from "../../hooks/auth_hooks";
import { Log } from "../../util/Logger";

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { onChangeAuthState } = useAuthHooks();

  useEffect(() => {
    Log.debug("firebase: sbscribe");
    ref.current = firebase.auth().onAuthStateChanged(onChangeAuthState);

    return () => {
      Log.debug(`firebase: unsubscribe onAuthStateChanged`);
      ref.current();
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <Meta />
      <NavBar isOpen={isOpen} setIsOpen={setIsOpen} />
      <ErrorBoundary>
        <Main>{children}</Main>
      </ErrorBoundary>
      <Footer />
    </>
  );
};

export default Layout;

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  left: 0;
  right: 0;

  font-family: 'HiraKakuProN-W3','Hiragino Kaku Gothic Pro','ヒラギノ角ゴ Pro W3','Yu Gothic','游ゴシック', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: inherit;

  color: #9ea2a8;

}

div {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus{
  outline: none;
}

*:focus {
  outline: none;
}

/* react-horizontal-scrolling-menu   */
.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}
/*----------*/
`;

const Main = styled.main`
  padding-top: ${theme.height.navBarSP};
  min-height: calc(100vh - ${theme.height.navBarSP} - ${theme.height.footerSP});

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding-top: ${theme.height.navBar};
    min-height: calc(100vh - ${theme.height.navBar} - ${theme.height.footer});
  }
`;
