import { useCallback, useContext, useRef, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { AuthContext } from "../context/auth_context";

interface IAuthHooks {
  onChangeAuthState: (user: firebase.User | null) => Promise<void>;
  loginGithub: () => Promise<void>;
  logout: () => Promise<void>;
}

const useAuthHooks = (): IAuthHooks => {
  const { setUser } = useContext(AuthContext);

  const onChangeAuthState = async (user: firebase.User | null) => {
    if (user) {
      setUser({
        id: user.uid,
        email: user.email,
        avatarURL: "",
      });
    } else {
      setUser(undefined);
    }
  };

  const loginGithub = async () => {
    try {
      const provider = new firebase.auth.GithubAuthProvider();
      await firebase.auth().signInWithPopup(provider);
    } catch (e) {
      throw e;
    }
  };

  const logout = async () => {
    try {
      await firebase.auth().signOut();
    } catch (e) {
      throw e;
    }
  };

  return { onChangeAuthState, loginGithub, logout };
};

export { useAuthHooks };
