import * as Sentry from "@sentry/browser";

export default class Logger {
  public static info = Logger.logFactory(console.info);
  public static warning = Logger.logFactory(console.warn);
  public static debug = Logger.logFactory(console.log);

  public static captureException(e: Error) {
    if (process.env.NODE_ENV === "development") {
      console.error(e);
    } else {
      Sentry.captureException(e);
    }
  }

  private static logFactory(
    consoleFunc: (message?: any, ...optionalParams: any[]) => void
  ) {
    return (message?: any, ...optionalParams: any[]) => {
      if (process.env.NODE_ENV === "development") {
        consoleFunc(message, ...optionalParams);
      }
    };
  }
}

export const Log = Logger;
