import * as React from "react";
import { FC, useContext, useState } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { theme } from "../theme";
import { Menu, Close } from "mdi-material-ui";
import { ProgressContext } from "../../context/progress";
import { LinearProgress } from "@material-ui/core";

export const navItemList: NavItemType[] = [
  {
    title: "POSTS",
    subTitle: "記事",
    url: "/",
  },
  {
    title: "SEARCH",
    subTitle: "検索",
    url: "/search",
  },
  {
    title: "PHOTOS",
    subTitle: "写真",
    url: "/photo-albums",
  },
  {
    title: "ABOUT",
    subTitle: "アバウト",
    url: "/about",
  },
  {
    title: "CONTACT",
    subTitle: "コンタクト",
    url: "/contact",
  },
];

interface NavBarProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export type NavItemType = {
  title: string;
  subTitle: string;
  url: string;
};

export const NavBar: FC<NavBarProps> = ({ isOpen, setIsOpen }) => {
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

  const { isLoading } = useContext(ProgressContext);

  const handlerOnClickMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  return (
    <Container>
      <TopContainer>
        <Logo onClick={() => navigate("/")}>
          <div>kame's blog</div>
          <div>Life is what you make it.</div>
        </Logo>

        <MenuSP onClick={handlerOnClickMenu}>
          {isShowMenu ? <Close /> : <Menu />}
        </MenuSP>
      </TopContainer>
      <BottomContainer isShow={isShowMenu}>
        <NavItemWrapper>
          {navItemList.map((item, index) => {
            return (
              <NavItem key={index} to={item.url}>
                <div>{item.title}</div>
              </NavItem>
            );
          })}
        </NavItemWrapper>
      </BottomContainer>
      {isLoading && <LinearProgress />}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: ${theme.ConstZIndex.NavBar};
  left: 0;
  right: 0;

  width: 100%;
  height: ${theme.height.navBarSP};

  background-color: ${theme.colors.white};
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    height: ${theme.height.navBar};
  }
`;

const TopContainer = styled.div`
  position: relative;

  margin: 0 auto;
  padding: 0 12px;

  height: ${theme.height.logoSp};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 0 20px;
    height: ${theme.height.logo};
  }
`;

const BottomContainer = styled.div<{ isShow: boolean }>`
  padding: 12px;

  background-color: #000000;
  display: ${(props) => (props.isShow ? "flex" : "none")};
  height: auto;
  justify-content: center;
  align-items: center;

  box-sizing: content-box;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 0;
    justify-content: flex-start;
    height: ${theme.height.navLink};

    display: flex;
  }
`;

const Logo = styled.div`
  & > :nth-child(1) {
    text-align: center;

    color: #111;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: -3px;

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      text-align: left;

      font-size: 32px;
    }
  }

  & > :nth-child(2) {
    text-align: center;

    font-size: 12px;

    letter-spacing: -0.5px;

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      text-align: left;

      font-size: 15px;
    }
  }

  :hover {
    cursor: pointer;
  }
`;

const NavItemWrapper = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  box-sizing: border-box;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    max-width: 1024px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const NavItem = styled(Link)`
  margin: 4px 0;
  padding: 0 ${theme.width.paddingSideSP};

  text-decoration: none;
  color: ${theme.colors.grey40};
  font-weight: 600;

  text-align: center;

  box-sizing: border-box;

  // Title
  & > div:nth-child(1) {
  }

  // SubTitle
  & > div:nth-child(2) {
    font-size: 0.5em;
  }

  :visited {
    color: ${theme.colors.grey40};
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 0 20px;
  }
`;

const MenuSP = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -12px);
  -webkit-transform: translate(-50%, -12px);
  -ms-transform: translate(-50%, -12px);

  height: auto;

  cursor: pointer;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    display: none;
  }
`;
