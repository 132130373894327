import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";

interface FooterProps {}

const Footer: FC<FooterProps> = ({}) => {
  return <Container />;
};

export default Footer;

const Container = styled.div`
  height: ${theme.height.footerSP};

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    height: ${theme.height.footer};
  }
`;
